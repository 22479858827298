// External Imports
import React from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";

// Internal Imports
import Views from "../view";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";

class FullRoutes extends React.Component {
  render() {
    const { auth, refetchAuth, ...rest } = this.props;
    return (
      <main style={{ backgroundColor: "#eee", height: "calc(100% - 64px)" }}>
        <Switch>
          <PublicRoute
            exact
            path="/"
            auth={auth.authenticated}
            Component={Views.SignIn}
            refetchAuth={refetchAuth}
            {...rest}
          />
          <PublicRoute
            exact
            path="/signout"
            auth={auth.authenticated}
            Component={Views.SignIn}
            refetchAuth={refetchAuth}
            {...rest}
          />
          <PublicRoute
            exact
            path="/signup"
            auth={auth.authenticated}
            Component={Views.SignUp}
            refetchAuth={refetchAuth}
            {...rest}
          />
          <PublicRoute
            exact
            path="/createcompany"
            auth={auth.authenticated}
            Component={Views.CreateCompany}
            refetchAuth={refetchAuth}
            {...rest}
          />
          <PublicRoute
            exact
            path="/SignIn"
            auth={auth.authenticated}
            Component={Views.SignIn}
            refetchAuth={refetchAuth}
            {...rest}
          />
          <Route
            path="/invite/:id"
            render={(props) => (
              <Views.RegisterUser {...props} refetchAuth={refetchAuth} />
            )}
          />
          <PublicRoute
            exact
            path="/passwordreset"
            auth={auth.authenticated}
            Component={Views.PasswordReset}
            refetchAuth={refetchAuth}
            {...rest}
          />
          <PublicRoute
            path="/resetpassword/:id"
            auth={auth.authenticated}
            Component={Views.ResetPassword}
            refetchAuth={refetchAuth}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            auth={auth.authenticated}
            Component={Views.Dashboard}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/residents"
            auth={auth.authenticated}
            Component={Views.Residents}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/properties"
            auth={auth.authenticated}
            Component={Views.Properties}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/add-property"
            auth={auth.authenticated}
            Component={Views.AddProperty}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/property/:id"
            auth={auth.authenticated}
            Component={Views.Property}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/building/:id"
            auth={auth.authenticated}
            Component={Views.Building}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/unit/:id"
            auth={auth.authenticated}
            Component={Views.Unit}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/resident/:id"
            auth={auth.authenticated}
            Component={Views.Resident}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/review-documents"
            auth={auth.authenticated}
            Component={Views.DocumentReview}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/library-documents"
            auth={auth.authenticated}
            Component={Views.DocumentLibrary}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/folder/:id"
            auth={auth.authenticated}
            Component={Views.Folder}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/library-settings"
            auth={auth.authenticated}
            Component={Views.LibrarySettings}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/manage-users"
            auth={auth.authenticated}
            Component={Views.ManageUsers}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/manage-office"
            auth={auth.authenticated}
            Component={Views.ManageOffice}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/admin-users"
            auth={auth.authenticated}
            Component={Views.AdminUsers}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/admin-company"
            auth={auth.authenticated}
            Component={Views.AdminCompany}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/account-settings"
            auth={auth.authenticated}
            Component={Views.Settings}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/account-manage"
            auth={auth.authenticated}
            Component={Views.ManageUsers}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/role-management"
            auth={auth.authenticated}
            Component={Views.RoleManagement}
            {...rest}
          />
          <Route component={Views.NoMatch} />
        </Switch>
      </main>
    );
  }
}
FullRoutes.propTypes = {
  auth: PropTypes.object,
  refetchAuth: PropTypes.func,
};
export default FullRoutes;
