// External Imports
import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import setFieldData from 'final-form-set-field-data';
import { withSnackbar } from 'notistack';
// Internal
import AddStaffForm from './form';
import Loading from '../../../common/loading';


const CREATE_OFFICE_PERMISSION = gql`
  mutation createOfficePermission(
    $officeId: ID!
    $userId: ID!
    $permissions: [String]
  ) {
    createOfficePermission(
      officeId: $officeId
      userId: $userId
      permissions: $permissions
    ) {
      success
      message
    }
  }
`;
const USERS = gql`
  query companyUsers {
    companyUsers {
        id
        firstName
        middleName
        lastName
        email
        officePhone
        mobilePhone
        title
    }
  }
`;
const OFFICES = gql`
  query companyOffices {
    companyOffices {
        id
        name
        website
        phone
        address1
        address2
        city
        state
        zip
        principal {
            firstName
            lastName
            title
        }
    }
  }
`;
function AddStaff(props) {
  const mutationCompleted = data => {
    props.enqueueSnackbar(data.createOfficePermission.message, {
      variant: data.createOfficePermission.success ? 'success' : 'error'
    });
  }
  const mutationFailed = () => {
    props.enqueueSnackbar("Add Staff Failed", {
      variant: 'error'
    });
  }
  const [createOfficePermission] = useMutation(CREATE_OFFICE_PERMISSION, {
    onCompleted: data => mutationCompleted(data),
    onError: data => mutationFailed(data)
  });

  const handleFormSubmit = (values, createOfficePermission, form) => {
    console.log(values)
    createOfficePermission({
      variables: { ...values }
    });
    Object.keys(values).forEach(key => {
      form.change(key, undefined);
      form.resetFieldState(key);
    });
  };
  const { loading: usersLoading, data: users } = useQuery(USERS);
  const { loading: officesLoading, data: offices } = useQuery(OFFICES);
  if (usersLoading || officesLoading)
    return <Loading />
  return (
    <Form
      onSubmit={(values, form) => {
        handleFormSubmit(values, createOfficePermission, form)
      }}
      mutators={{
        setFieldData,
        setValue: (args, state, utils) => {
          utils.changeValue(state, args[0], () => args[1]);
        }
      }}
      render={({
        handleSubmit,
        form
      }) => {
        return <AddStaffForm
          handleSubmit={handleSubmit}
          form={form}
          users={users.companyUsers}
          offices={offices.companyOffices}
        />
      }}
    />
  );
}

AddStaff.propTypes = {
  refetchAuth: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func
}
export default withRouter(withSnackbar(AddStaff));
