// External Imports
import React, { Component } from 'react';
// External Components
import { Select, Checkboxes } from 'mui-rff';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
// Internal Imports
import FormWrapper from '../../../common/formWrapper';
import FormError from '../../../common/formError';
import officePermissions from '../../officePermissions';

const styles = () => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20
  }
});

class Form extends Component {
  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      reset,
      submitting,
      error,
      form,
      users,
      offices
    } = this.props;

    return (
      <FormWrapper size="large">
        <form onSubmit={handleSubmit} className={classes.formWrapper}>
          <h1>Add Office Staff</h1>
          <Grid spacing={2} container alignItems="center" justify="center">
            <Grid item xs={8}>
              <Select
                name="userId"
                label="Select User"
                formControlProps={{ fullWidth: true }}
                required={true}
              >
                {users.map(user => (
                  <MenuItem key={user.id} value={user.id}>
                    {`${user.firstName} ${user.lastName} (${user.title})`}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="officeId"
                label="Select Office"
                formControlProps={{ fullWidth: true }}
                required={true}
              >
                {offices.map(office => (
                  <MenuItem key={office.id} value={office.id}>
                    {`${office.name}`}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={8}>
              <Checkboxes
                label="Select Permissions"
                name="permissions"
                data={officePermissions}
              />
            </Grid>
            {error && (
              <Grid item xs={8}>
                <FormError errorText={error} />
              </Grid>
            )}
          </Grid>
          <div className={classes.formButtonWrapper}>
            <Button disabled={pristine || submitting} onClick={reset}>
              Clear Values
            </Button>
            <Button type="submit" disabled={submitting}>
              Submit
            </Button>
          </div>
        </form>
      </FormWrapper>
    );
  }
}
Form.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  companies: PropTypes.array,
  error: PropTypes.object,
  form: PropTypes.object,
  users: PropTypes.array,
  offices: PropTypes.array
};
export default withStyles(styles)(Form);
