import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import FindInPage from "@material-ui/icons/FindInPage";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// Internal
import DrawerItem from "./drawerItem";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

class ReviewList extends React.Component {
  state = { open: false };

  handleClick = () => {
    this.props.toggleDrawerList("review");
  };

  render() {
    const { classes, drawerLists } = this.props;
    const open = drawerLists["review"];
    return (
      <div className={classes.root}>
        <List component="nav">
          <ListItem button onClick={this.handleClick}>
            <ListItemIcon>
              <LibraryBooks />
            </ListItemIcon>
            <ListItemText primary="Document Review" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <DrawerItem
                linkTo="/review-documents"
                Icon={FindInPage}
                text="Review Documents"
              />
            </List>
          </Collapse>
        </List>
      </div>
    );
  }
}

ReviewList.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerList: PropTypes.func,
  drawerLists: PropTypes.element,
  auth: PropTypes.object,
};

export default withStyles(styles)(ReviewList);
