// External Imports
import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import setFieldData from 'final-form-set-field-data';
import { withSnackbar } from 'notistack';
// Internal
import CreateOfficeForm from './form';
import Loading from '../../../common/loading';

const CREATE_OFFICE = gql`
  mutation createCompanyOffice(
    $name: String!
    $address1: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
    $placeId: String!
    $phone: String!
    $website: String!
    $principalId: ID!
  ) {
    createCompanyOffice(
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      placeId: $placeId
      phone: $phone
      website: $website
      principalId: $principalId
    ) {
      success
      message
    }
  }
`;
const USERS = gql`
  query companyUsers {
    companyUsers {
        id
        firstName
        middleName
        lastName
        email
        officePhone
        mobilePhone
        title
        offices {
            name
            id
        }
        company {
            name
            id
        }
    }
  }
`;
function CreateOffice(props) {
  const mutationCompleted = data => {
    props.enqueueSnackbar(data.createCompanyOffice.message, {
      variant: data.createCompanyOffice.success ? 'success' : 'error'
    });
  }
  const mutationFailed = () => {
    props.enqueueSnackbar("Office Failed to Create", {
      variant: 'error'
    });
  }
  const [createCompanyOffice] = useMutation(CREATE_OFFICE, {
    onCompleted: data => mutationCompleted(data),
    onError: data => mutationFailed(data)
  });

  const handleFormSubmit = (values, createCompanyOffice, form) => {
    console.log(values)
    createCompanyOffice({
      variables: { ...values }
    });
    Object.keys(values).forEach(key => {
      form.change(key, undefined);
      form.resetFieldState(key);
    });
  };
  const { loading, data } = useQuery(USERS);
  if (loading)
    return <Loading />
  return (
    <Form
      onSubmit={(values, form) => {
        handleFormSubmit(values, createCompanyOffice, form)
      }}
      mutators={{
        setFieldData,
        setValue: (args, state, utils) => {
          utils.changeValue(state, args[0], () => args[1]);
        }
      }}
      render={({
        handleSubmit,
        form
      }) => (
        <CreateOfficeForm
          onSubmit={handleSubmit.bind(null, form)}
          form={form}
          users={data.companyUsers}
        />
      )}
    />
  );
}

CreateOffice.propTypes = {
  refetchAuth: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func
}
export default withRouter(withSnackbar(CreateOffice));
