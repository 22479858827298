import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";
import { withSnackbar } from "notistack";
import MaterialTable from "material-table";
import _ from "lodash";
import Loading from "../../common/loading";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  breadcrumbs: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  main: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
});

const RESIDENTS = gql`
  query officeResidents($id: ID!) {
    officeResidents(id: $id) {
      id
      firstName
      lastName
      status
      leaseStart
      leaseEnd
      initialContactDate
      initialContact
      followUpContactDate
      followUpContact
      interviewedDate
      interviewed
      qualifiedDate
      qualified
      unit {
        id
        identifier
        building {
          id
          address1
          property {
            id
            name
            address1
          }
        }
      }
    }
  }
`;

function OfficeResidents(props) {
  const history = props.history;
  const id = props.office.id;
  const { loading, data } = useQuery(RESIDENTS, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  if (loading) return <Loading />;
  const officeResidents = data.officeResidents;
  const residents = _.cloneDeep(officeResidents);
  return (
    <MaterialTable
      title="Residents"
      style={{ marginTop: "32px" }}
      onRowClick={(event, rowData) => {
        event.preventDefault();
        history.push(`/resident/${rowData.id}`);
      }}
      columns={[
        { title: "First Name", field: "firstName" },
        { title: "Last Name", field: "lastName" },
        { title: "Unit Identified", field: "unit.identifier" },
        { title: "Building Address", field: "unit.building.address1" },
        { title: "Property", field: "unit.building.property.name" },
        { title: "Property Address", field: "unit.building.property.address1" },
        { title: "Property Owner", field: "unit.building.property.owner" },
      ]}
      data={residents}
    />
  );
}
OfficeResidents.dispalyName = "OfficeResidents";
OfficeResidents.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  office: PropTypes.object,
};

export default withSnackbar(withStyles(styles)(OfficeResidents));
