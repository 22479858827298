// External Imports
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useMutation, gql } from "@apollo/client";
import _ from "lodash";
import { withSnackbar } from "notistack";
// Internal Imports

const DELETE_BUILDING = gql`
  mutation deleteBuilding($id: ID!) {
    deleteBuilding(id: $id) {
      success
      message
    }
  }
`;

const styles = (theme) => ({
  table: {
    marginTop: theme.spacing(1),
  },
  buttonContent: {
    margin: theme.spacing(1),
  },
});

function Units(props) {
  const history = props.history;
  const deleteBuildingCompleted = async (data) => {
    props.enqueueSnackbar(data.deleteBuilding.message, {
      variant: data.deleteBuilding.success ? "success" : "error",
    });
    await props.refetchUnits();
  };
  const deleteBuildingFailed = (data) => {
    props.enqueueSnackbar(data.deleteBuilding.message, {
      variant: "error",
    });
  };
  const [deleteBuilding] = useMutation(DELETE_BUILDING, {
    onCompleted: (data) => deleteBuildingCompleted(data),
    onError: (data) => deleteBuildingFailed(data),
  });
  const units = _.cloneDeep(props.units);
  return (
    <MaterialTable
      title="Units"
      style={{ marginTop: "32px" }}
      onRowClick={(event, rowData) => {
        event.preventDefault();
        history.push(`/unit/${rowData.id}`);
      }}
      actions={[
        {
          icon: "delete",
          tooltip: "Delete Unit",
          onClick: (event, rowData) => {
            event.preventDefault();
            deleteBuilding({ variables: { id: rowData.id } });
          },
        },
      ]}
      columns={[{ title: "Identifier", field: "identifier" }]}
      data={units}
    />
  );
}

Units.propTypes = {
  classes: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  refetchUnits: PropTypes.func,
  units: PropTypes.array,
  history: PropTypes.object,
};

export default withStyles(styles)(withSnackbar(Units));
