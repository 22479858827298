// External Imports
import React from 'react';
import PropTypes from "prop-types";
// External Components
import { TextField, Autocomplete } from 'mui-rff';
import { Grid,  Checkbox as MuiCheckbox } from '@material-ui/core';

const RequestForm = ({ tags = [], users = [] }) => {
    debugger; // eslint-disable-line no-debugger
    return (
        <Grid spacing={2} container alignItems="center" justify="center">
            <Grid item xs={10}>
                <TextField
                    label="Name"
                    fullWidth
                    name="name"
                    placeholder="Name"
                    required={true}
                />
            </Grid>
            <Grid item xs={10}>
                <Autocomplete
                    label="Choose Tags"
                    name="tags"
                    multiple={true}
                    variant="outlined"
                    options={tags}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    disableCloseOnSelect={true}
                    renderOption={(option, { selected }) => (
                        <>
                            <MuiCheckbox style={{ marginRight: 8 }} checked={selected} />
                            {option.label}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={10}>
                <Autocomplete
                    label="User Access"
                    name="tags"
                    multiple={true}
                    variant="outlined"
                    options={users}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    disableCloseOnSelect={true}
                    renderOption={(option, { selected }) => (
                        <>
                            <MuiCheckbox style={{ marginRight: 8 }} checked={selected} />
                            {option.label}
                        </>
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default RequestForm;


RequestForm.propTypes = {
    tags: PropTypes.array,
    users: PropTypes.array,
  };
  
