// External Imports
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Form } from "react-final-form";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import setFieldData from "final-form-set-field-data";
import { withSnackbar } from "notistack";
// Internal
import CreateCompanyForm from "./form";

const CREATE_BUILDING = gql`
  mutation createBuilding(
    $propertyId: ID!
    $type: String!
    $address1: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
    $placeId: String!
  ) {
    createBuilding(
      propertyId: $propertyId
      type: $type
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      placeId: $placeId
    ) {
      success
      message
    }
  }
`;

function CreateCompany(props) {
  const propertyId = props.propertyId;

  const mutationCompleted = async (data) => {
    props.enqueueSnackbar(data.createBuilding.message, {
      variant: data.createBuilding.success ? "success" : "error",
    });
    await props.refetchBuildings();
  };
  const mutationFailed = () => {
    props.enqueueSnackbar("Create Company Failed", {
      variant: "error",
    });
  };
  const [createBuilding] = useMutation(CREATE_BUILDING, {
    onCompleted: (data) => mutationCompleted(data),
    onError: (data) => mutationFailed(data),
  });

  const handleFormSubmit = (values, createBuilding, propertyId) => {
    console.log(propertyId);
    createBuilding({
      variables: { propertyId, ...values },
    });
  };
  return (
    <Form
      onSubmit={(values) =>
        handleFormSubmit(values, createBuilding, propertyId)
      }
      mutators={{
        setFieldData,
        setValue: (args, state, utils) => {
          utils.changeValue(state, args[0], () => args[1]);
        },
      }}
      render={({ handleSubmit, form }) => (
        <CreateCompanyForm onSubmit={handleSubmit} form={form} />
      )}
    />
  );
}

CreateCompany.propTypes = {
  refetchAuth: PropTypes.func,
  refetchBuildings: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  propertyId: PropTypes.string,
};
export default withRouter(withSnackbar(CreateCompany));
