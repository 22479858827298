// External Imports
import React from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { useQuery, useMutation, gql } from '@apollo/client';
import _ from 'lodash';
// Internal Imports
import Loading from '../../common/loading';

const DELETE_OFFICE = gql`
  mutation deleteOffice(
    $id: ID!
  ) {
    deleteOffice(
      id: $id
    ) {
      success
      message
    }
  }
`;

const OFFICES = gql`
  query companyOffices {
    companyOffices {
        id
        name
        website
        phone
        address1
        address2
        city
        state
        zip
        principal {
            firstName
            lastName
            title
        }
    }
  }
`;

const styles = theme => ({
    table: {
        marginTop: theme.spacing(1)
    },
    buttonContent: {
        margin: theme.spacing(1)
    },
});

const addressString = company => {
    let addressString = ''
    addressString += company.address1
    if(company.address2)
        addressString += `, ${company.address2}`
    addressString += `, ${company.city}`
    addressString += `, ${company.state}`
    addressString += `, ${company.zip}`
    return addressString
}

function Users(props) {
    const deletUserCompleted = data => {
        props.enqueueSnackbar(data.deleteOffice.message, {
            variant: data.deleteOffice.success ? 'success' : 'error'
        });
    }
    const deleteOfficeFailed = data => {
        props.enqueueSnackbar(data.deleteOffice.message, {
            variant: 'error'
        });
    }
    const [deleteOffice] = useMutation(DELETE_OFFICE, {
        onCompleted: data => deletUserCompleted(data),
        onError: data => deleteOfficeFailed(data)
    });
    const { loading, data } = useQuery(OFFICES);
    if (loading) {
        return <Loading />
    } else {
        const companyOffices = _.cloneDeep(data.companyOffices);
        return (
            <MaterialTable
                title="Offices"
                style={{paddingLeft: '0px', marginTop: '32px'}}
                actions={[
                    {
                        icon: 'delete',
                        tooltip: 'Delete Company',
                        onClick: (event, rowData) => {
                            event.preventDefault()
                            deleteOffice({ variables: { id: rowData.id } })
                        }
                    }
                ]}
                columns={[
                    { title: "Name", field: "name" },
                    { title: "Address", render: rowData =>  addressString(rowData) },
                    { title: "Principal", render: rowData =>  `${rowData.principal.firstName} ${rowData.principal.lastName}` },
                    { title: "Phone", field: "phone" },
                    { title: "Website", field: "website" },
                ]}
                data={companyOffices}
            />
        )
    }
}

Users.propTypes = {
    classes: PropTypes.object,
    enqueueSnackbar: PropTypes.func
}

export default withStyles(styles)(Users);
