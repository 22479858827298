// External Imports
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useMutation, gql } from "@apollo/client";
import _ from "lodash";
import { withSnackbar } from "notistack";
import moment from "moment";
// Internal Imports

const DELETE_PROPERTY = gql`
  mutation deleteProperty($id: ID!) {
    deleteProperty(id: $id) {
      success
      message
    }
  }
`;

const styles = (theme) => ({
  table: {
    marginTop: theme.spacing(1),
  },
  buttonContent: {
    margin: theme.spacing(1),
  },
});

const addressString = (property) => {
  let string = "";
  string += `${property.address1}, `;
  if (property.address2) string += `${property.address2}, `;
  string += `${property.city}, `;
  string += `${property.state}`;
  return string;
};
const jurisdictionString = (property) => {
  let string = "";
  string += `${property.jurisdictionAddress1}, `;
  if (property.jurisdictionAddress2)
    string += `${property.jurisdictionAddress2}, `;
  string += `${property.jurisdictionCity}, `;
  string += `${property.jurisdictionState}`;
  return string;
};

function Properties(props) {
  const history = props.history;
  const deletePropertyCompleted = (data) => {
    props.enqueueSnackbar(data.deleteProperty.message, {
      variant: data.deleteProperty.success ? "success" : "error",
    });
  };
  const deletePropertyFailed = (data) => {
    props.enqueueSnackbar(data.deleteProperty.message, {
      variant: "error",
    });
  };
  const [deleteProperty] = useMutation(DELETE_PROPERTY, {
    onCompleted: (data) => deletePropertyCompleted(data),
    onError: (data) => deletePropertyFailed(data),
  });
  const properties = _.cloneDeep(props.properties);
  return (
    <MaterialTable
      title="Properties"
      style={{ marginTop: "32px" }}
      onRowClick={(event, rowData) => {
        event.preventDefault();
        history.push(`/property/${rowData.id}`);
      }}
      actions={[
        {
          icon: "delete",
          tooltip: "Delete Property",
          onClick: (event, rowData) => {
            event.preventDefault();
            deleteProperty({ variables: { id: rowData.id } });
          },
        },
      ]}
      columns={[
        { title: "Name", field: "name" },
        { title: "Owner", field: "owner" },
        { title: "Address", render: (rowData) => addressString(rowData) },
        {
          title: "Jurisdiction",
          render: (rowData) => jurisdictionString(rowData),
        },
        { title: "Website", field: "website" },
        { title: "Phone", field: "phone" },
        {
          title: "Date of Construction",
          render: (rowData) =>
            rowData.dateOfConstruction
              ? moment(Date(rowData.dateOfConstruction)).format("MMMM Do YY")
              : "N/A",
        },
        { title: "Number of Units", field: "numberOfUnits" },
      ]}
      data={properties}
    />
  );
}

Properties.propTypes = {
  classes: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  properties: PropTypes.array,
  history: PropTypes.object,
};

export default withStyles(styles)(withSnackbar(Properties));
