import React from "react";
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import Loading from "../common/loading";
import { useQuery, useMutation, gql } from "@apollo/client";
import _ from "lodash";

const styles = (theme) => ({
  formButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    margin: 20,
  },
  wrapper: {
    marginTop: theme.spacing(),
    width: "90%",
    marginLeft: theme.spacing(),
  },
});

const TAGS = gql`
  query tags {
    tags {
      id
      name
    }
  }
`;

const CREATE_TAG = gql`
  mutation createTag($name: String!) {
    createTag(name: $name) {
      success
      message
    }
  }
`;

const DELETE_TAG = gql`
  mutation deleteTag($id: ID!) {
    deleteTag(id: $id) {
      success
      message
    }
  }
`;
const renderTable = (
  props,
  tableState,
  setTableState,
  refetch,
  createTag,
  deleteTag,
  tags
) => {
  return (
    <MaterialTable
      title="Manage Library Tags"
      columns={tableState.columns}
      data={tags}
      options={{
        addRowPosition: "first",
        pageSizeOptions: [5, 10, 20],
      }}
      editable={{
        onRowAdd: async (newData) => {
          createTag({
            variables: {
              name: newData.name,
            },
          });
        },
        onRowDelete: async (oldData) => {
          deleteTag({ variables: { id: oldData.id } });
        },
      }}
    />
  );
};
const Tags = (props) => {
  const { loading, refetch, data } = useQuery(TAGS);
  const deleteTagCompleted = (data) => {
    refetch();
    props.enqueueSnackbar(data.deleteTag.message, {
      variant: data.deleteTag.success ? "success" : "error",
    });
  };
  const deleteTagFailed = (data) => {
    props.enqueueSnackbar(data.deleteTag.message, {
      variant: "error",
    });
  };
  const createTagCompleted = (data) => {
    refetch();
    props.enqueueSnackbar(data.createTag.message, {
      variant: data.createTag.success ? "success" : "error",
    });
  };
  const createTagFailed = (data) => {
    props.enqueueSnackbar(data.createTag.message, {
      variant: "error",
    });
  };
  const [deleteTag] = useMutation(DELETE_TAG, {
    onCompleted: (data) => deleteTagCompleted(data),
    onError: (data) => deleteTagFailed(data),
  });
  const [createTag] = useMutation(CREATE_TAG, {
    onCompleted: (data) => createTagCompleted(data),
    onError: (data) => createTagFailed(data),
  });
  const [tableState, setTableState] = React.useState({
    columns: [{ title: "Name", field: "name" }],
  });
  if (loading) {
    return <Loading />;
  } else {
    const tags = _.cloneDeep(data.tags);
    return renderTable(
      props,
      tableState,
      setTableState,
      refetch,
      createTag,
      deleteTag,
      tags
    );
  }
};
Tags.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(Tags));
