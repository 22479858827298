import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useQuery, gql } from '@apollo/client';
import { withSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../common/TabPanel';
import CompanyRoles from './company';
import OfficeRoles from './office';
import Loading from '../../common/loading'
const styles = theme => ({
    root: {
        flexGrow: 1
    },
    main: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        marginLeft: theme.spacing(0.5)
    }
});

const COMPANY = gql`
  query userCompany {
    userCompany {
        id
        name
        companyPermissions {
            id
            companyPermissionSet
            user {
                id
                firstName
                lastName
            }
        }
        offices {
            id
            name
            officePermissions {
                id
                officePermissionSet
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
  }
`;

const createOfficeTabs = (offices) => (
    offices.map((office) => <Tab key={office.id} label={office.name} value={office.id} />)
)
const createOfficePanels = (offices, value) => (
    offices.map((office) =>
        <TabPanel key={office.id} value={value} index={office.id}>
            <OfficeRoles office={office} />;
        </TabPanel>
    )
)


function ManageRoles(props) {
    const [state, setState] = useState({
        value: 'company'
    });

    const handleChange = (event, value) => {
        setState({ value });
    };

    const { classes } = props;
    const { loading, data, refetch } = useQuery(COMPANY, {
        fetchPolicy: "network-only"
    });
    if (loading)
        return <Loading />
    const company = data.userCompany
    const offices = company.offices
    return (
        <main className={classes.main}>
            <Paper className={classes.root}>
                <Tabs
                    value={state.value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Company Permissions" value="company" />
                    {offices.length > 1 && createOfficeTabs(offices)}
                </Tabs>
            </Paper>
            <TabPanel value={state.value} index="company">
                <CompanyRoles company={company} refetch={refetch}/>
            </TabPanel>
            {offices.length > 1 && createOfficePanels(offices, state.value, refetch)}
        </main>
    );
}
ManageRoles.dispalyName = "ManageRoles"
ManageRoles.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(ManageRoles));
