// External Imports
import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import setFieldData from 'final-form-set-field-data';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
// Internal
import RegisterUserForm from './form';
import Loading from '../common/loading';
const GET_INVITE = gql`
  query invite($id: ID!) {
    invite(id: $id) {
      id
      person {
        id
        firstName
        middleName
        lastName
        officePhone
        mobilePhone
      }
    }
  }
`;

const REGISTER = gql`
  mutation register(
    $inviteId: ID!
    $firstName: String!
    $middleName: String
    $lastName: String!
    $officePhone: String
    $mobilePhone: String
    $password: String!
  ) {
    register(
      inviteId: $inviteId
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      officePhone: $officePhone
      mobilePhone: $mobilePhone
      password: $password
    ) {
      success
      message
      data
    }
  }
`;

const styles = () => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20
  }
});

function RegisterUser(props) {
  const { match, refetchAuth, history, enqueueSnackbar } = props
  const inviteId = match.params.id;
  const { loading, data } = useQuery(GET_INVITE, {
    variables: { id: inviteId },
  });
  const mutationCompleted = async (data) => {
    enqueueSnackbar(data.register.message, {
      variant: data.register.success ? 'success' : 'error'
    });
    if(data.register.success){
      localStorage.setItem('token', data.register.data);
      await refetchAuth()
      history.push('/dashboard');
    }
  }
  const mutationFailed = () => {
    enqueueSnackbar("Registration Failed", {
      variant: 'error'
    });
  }
  const [register] = useMutation(REGISTER, {
    onCompleted: data => mutationCompleted(data),
    onError: data => mutationFailed(data)
  });
  const handleFormSubmit = (values, register) => {
    console.log(values)
    register({
      variables: { inviteId, ...values }
    });
  };

  if (loading)
    return <Loading />
  console.log(data)
  return (
    <Form
      onSubmit={values => handleFormSubmit(values, register)}
      mutators={{
        setFieldData,
        setValue: (args, state, utils) => {
          utils.changeValue(state, args[0], () => args[1]);
        }
      }}
      initialValues={{ ...data.invite.person }}
      render={({
        handleSubmit,
        form,
        reset
      }) => (
        <RegisterUserForm
          onSubmit={handleSubmit}
          form={form}
          reset={reset}
        />
      )}
    />
  );
}

RegisterUser.propTypes = {
  refetchAuth: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  classes: PropTypes.object,
  match: PropTypes.object
}
export default withStyles(styles)(withRouter(withSnackbar(RegisterUser)));
