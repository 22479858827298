// External Imports
import React from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { useMutation, gql } from '@apollo/client';
import { withSnackbar } from 'notistack';
import _ from 'lodash';
// Internal Imports

const DELETE_OFFICE_PERMISSION = gql`
  mutation deleteOfficePermission(
    $id: ID!
  ) {
    deleteOfficePermission(
      id: $id
    ) {
      success
      message
    }
  }
`;

const styles = theme => ({
    table: {
        marginTop: theme.spacing(1)
    },
    buttonContent: {
        margin: theme.spacing(1)
    },
});

function Staff(props) {
    const deletUserCompleted = data => {
        props.enqueueSnackbar(data.deleteOfficePermission.message, {
            variant: data.deleteOfficePermission.success ? 'success' : 'error'
        });
        props.refetch()
    }
    const deleteOfficePermissionFailed = () => {
        props.enqueueSnackbar("Delete Office User Failed", {
            variant: 'error'
        });
    }
    const [deleteOfficePermission] = useMutation(DELETE_OFFICE_PERMISSION, {
        onCompleted: data => deletUserCompleted(data),
        onError: data => deleteOfficePermissionFailed(data)
    });
    const officePermissions = _.cloneDeep(props.office.officePermissions);
    return (
        <MaterialTable
            title="Office Staff"
            style={{ marginTop: '32px' }}
            actions={[
                {
                    icon: 'delete',
                    tooltip: 'Delete User',
                    onClick: (event, rowData) => {
                        event.preventDefault()
                        deleteOfficePermission({ variables: { id: rowData.id } })
                    }
                }
            ]}
            columns={[
                { title: "First Name", field: "user.firstName" },
                { title: "Last Name", field: "user.lastName" },
                { title: "Title", field: "user.title" },
                { title: "Email", field: "user.email" },
            ]}
            data={officePermissions}
        />
    )
}

Staff.propTypes = {
    classes: PropTypes.object,
    enqueueSnackbar: PropTypes.func,
    office: PropTypes.object,
    refetch: PropTypes.func
}

export default withStyles(styles)(withSnackbar(Staff));
