import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";
import { withSnackbar } from "notistack";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../common/TabPanel";
import Loading from "../../common/loading";
import AddBuilding from "./addBuilding";
import Buildings from "./buildings";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  main: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
});

const BUILDINGS = gql`
  query propertyBuildings($propertyId: ID!) {
    propertyBuildings(propertyId: $propertyId) {
      id
      type
      address1
      address2
      city
      state
      zip
      placeId
    }
  }
`;

function Properties(props) {
  const [state, setState] = useState({
    value: "building",
  });

  const handleChange = (event, value) => {
    setState({ value });
  };

  const { classes, match, history } = props;
  const propertyId = match.params.id;
  const { loading, data, refetch } = useQuery(BUILDINGS, {
    variables: { propertyId },
    fetchPolicy: "network-only",
  });
  if (loading) return <Loading />;
  const buildings = data.propertyBuildings;
  return (
    <main className={classes.main}>
      <Paper className={classes.root}>
        <Tabs
          value={state.value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab key={"building"} label={"Buildings"} value={"building"} />
          <Tab
            key={"addBuilding"}
            label={"Add Building"}
            value={"addBuilding"}
          />
        </Tabs>
      </Paper>
      <TabPanel key={"building"} value={state.value} index={"building"}>
        <Buildings
          buildings={buildings}
          propertyId={propertyId}
          history={history}
          refetchBuildings={refetch}
        />
      </TabPanel>
      <TabPanel key={"addBuilding"} value={state.value} index={"addBuilding"}>
        <AddBuilding propertyId={propertyId} refetchBuildings={refetch} />
      </TabPanel>
    </main>
  );
}
Properties.dispalyName = "Properties";
Properties.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withSnackbar(withStyles(styles)(Properties));
