import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useQuery, gql } from '@apollo/client';
import { withSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../common/TabPanel';
import Loading from '../../common/loading';
import PropertyTable from './table';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    main: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        marginLeft: theme.spacing(0.5)
    }
});

const OFFICES = gql`
  query companyOffices {
    companyOffices {
        id
        name
        properties {
            id
            name
            owner
            address1
            address2
            city
            state
            zip
            placeId
            jurisdictionAddress1
            jurisdictionAddress2
            jurisdictionCity
            jurisdictionState
            jurisdictionZip
            jurisdictionPlaceId
            phone
            website
            dateOfConstruction
            numberOfUnits
        }
        officePermissions {
            id
            user {
                id
                firstName
                lastName
                title
                email
            }
        }
    }
  }
`;

const createOfficeTabs = (offices) => (
    offices.map((office, index) => <Tab key={index} label={office.name} value={office.id} />)
)
const createOfficePanels = (offices, value, history, refetch) => (
    offices.map((office, index) =>
        <TabPanel key={index} value={value} index={office.id}>
            <PropertyTable properties={office.properties} history={history} refetch={refetch}/>
        </TabPanel>
    )
)


function Properties(props) {
    const [state, setState] = useState({
        value: 'defualt'
    });

    const handleChange = (event, value) => {
        setState({ value });
    };

    const { classes, history } = props;
    const { loading, data, refetch } = useQuery(OFFICES, {
        fetchPolicy: "network-only"
    });
    if (loading)
        return <Loading />
    const offices = data.companyOffices
    if(offices.length < 1)
        return <p>Please Add Office(s) to Utilize Properties</p>
    if(state.value === 'defualt') setState({value: offices[0].id})
    return (
        <main className={classes.main}>
            <Paper className={classes.root}>
                <Tabs
                    value={state.value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    {createOfficeTabs(offices)}
                </Tabs>
            </Paper>
            {createOfficePanels(offices, state.value, history, refetch)}
        </main>
    );
}
Properties.dispalyName = "Properties"
Properties.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object
};

export default withSnackbar(withStyles(styles)(Properties));
