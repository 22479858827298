// External Imports
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import setFieldData from 'final-form-set-field-data';
import { withSnackbar } from 'notistack';
// Internal
import UpdateResidentForm from './form';

const UPDATE_RESIDNET = gql`
  mutation updateResident(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $status: String
    $leaseStart: Date
    $leaseEnd: Date
    $initialContactDate: Date
    $initialContact: Boolean
    $followUpContactDate: Date
    $followUpContact: Boolean
    $interviewedDate: Date
    $interviewed: Boolean
    $qualifiedDate: Date
    $qualified: Boolean
  ) {
    updateResident(
      id: $id
      firstName: $firstName
      lastName: $lastName
      status: $status
      leaseStart: $leaseStart
      leaseEnd: $leaseEnd
      initialContactDate: $initialContactDate
      initialContact: $initialContact
      followUpContactDate: $followUpContactDate
      followUpContact: $followUpContact
      interviewedDate: $interviewedDate
      interviewed: $interviewed
      qualifiedDate: $qualifiedDate
      qualified: $qualified
    ) {
      success
      message
    }
  }
`;

function CreateResident(props) {
  const id = props.resident.id;

  const mutationCompleted = data => {
    props.enqueueSnackbar(data.updateResident.message, {
      variant: data.updateResident.success ? 'success' : 'error'
    });
  }
  const mutationFailed = () => {
    props.enqueueSnackbar("Create Company Failed", {
      variant: 'error'
    });
  }
  const [updateResident] = useMutation(UPDATE_RESIDNET, {
    onCompleted: data => mutationCompleted(data),
    onError: data => mutationFailed(data)
  });

  const handleFormSubmit = (values, updateResident, id) => {
    updateResident({
      variables: {
        id,
        ...values
      }
    });
  };
  return (
    <Form
      onSubmit={values => handleFormSubmit(values, updateResident, id)}
      initialValues={props.resident}
      mutators={{
        setFieldData,
        setValue: (args, state, utils) => {
          utils.changeValue(state, args[0], () => args[1]);
        }
      }}
      render={({
        handleSubmit,
        form
      }) => (
        <UpdateResidentForm
          onSubmit={handleSubmit}
          form={form}
        />
      )}
    />
  );
}

CreateResident.propTypes = {
  refetchAuth: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  resident: PropTypes.object
}
export default withRouter(withSnackbar(CreateResident));
