// External Imports
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import setFieldData from 'final-form-set-field-data';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
// Internal
import CompanyInviteForm from './form';

const COMPANY_INVITE = gql`
  mutation companyInvite(
    $firstName: String!
    $middleName: String
    $lastName: String!
    $email: String!
    $officePhone: String
    $mobilePhone: String
    $title: String
  ) {
    companyInvite(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      email: $email
      officePhone: $officePhone
      mobilePhone: $mobilePhone
      title: $title
    ) {
      success
      message
    }
  }
`;

const styles = () => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20
  }
});

function CompanyInvite(props) {
  const mutationCompleted = data => {
    props.enqueueSnackbar(data.companyInvite.message, {
      variant: data.companyInvite.success ? 'success' : 'error'
    });
  }
  const mutationFailed = () => {
    props.enqueueSnackbar("Invite Failed", {
      variant: 'error'
    });
  }
  const [companyInvite] = useMutation(COMPANY_INVITE, {
    onCompleted: data => mutationCompleted(data),
    onError: data => mutationFailed(data)
  });
  const handleFormSubmit = (values, companyInvite) => {
    console.log(values)
    companyInvite({
      variables: { ...values }
    });
  };
  return (
    <Form
      onSubmit={values => handleFormSubmit(values, companyInvite)}
      mutators={{
        setFieldData,
        setValue: (args, state, utils) => {
          utils.changeValue(state, args[0], () => args[1]);
        }
      }}
      render={({
        handleSubmit,
        form,
        reset
      }) => (
        <CompanyInviteForm
          onSubmit={handleSubmit}
          form={form}
          reset={reset}
        />
      )}
    />
  );
}

CompanyInvite.propTypes = {
  refetchAuth: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  classes: PropTypes.object
}
export default withStyles(styles)(withRouter(withSnackbar(CompanyInvite)));
