// External Imports
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Form } from "react-final-form";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import setFieldData from "final-form-set-field-data";
import { withSnackbar } from "notistack";
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
// Internal
import Upload from '../../../common/upload';
import UpdateComplaintForm from "./form";

const UPDATE_COMPLAINT = gql`
  mutation updateComplaint($id: ID!, $number: String!, $filingDate: Date) {
    updateComplaint(id: $id, number: $number, filingDate: $filingDate) {
      success
      message
    }
  }
`;

function UpdateComplaint(props) {

  const mutationCompleted = (data) => {
    props.enqueueSnackbar(data.updateComplaint.message, {
      variant: data.updateComplaint.success ? "success" : "error",
    });
    props.refetchResident()
  };
  const mutationFailed = () => {
    props.enqueueSnackbar("Update Complaint Failed", {
      variant: "error",
    });
  };
  const [updateComplaint] = useMutation(UPDATE_COMPLAINT, {
    onCompleted: (data) => mutationCompleted(data),
    onError: (data) => mutationFailed(data),
  });

  const handleFormSubmit = (values, updateComplaint, id) => {
    updateComplaint({
      variables: {
        id,
        ...values,
      },
    });
  };
  return (
    <Paper>
      <Form
        onSubmit={(values) => handleFormSubmit(values, updateComplaint, props.complaint.id)}
        initialValues={props.complaint}
        mutators={{
          setFieldData,
          setValue: (args, state, utils) => {
            utils.changeValue(state, args[0], () => args[1]);
          },
        }}
        render={({ handleSubmit, form }) => (
          <UpdateComplaintForm onSubmit={handleSubmit} form={form} />
        )}
      />
      <Grid spacing={2} container alignItems="center" justify="center">
        <Grid item xs={8}>
        <Upload uploadLabel={"Add Lease Document"} />
        </Grid>
        </Grid>
    </Paper>
  );
}

UpdateComplaint.propTypes = {
        refetchAuth: PropTypes.func,
  refetchResident: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  resident: PropTypes.object,
  complaint: PropTypes.object,
};
export default withRouter(withSnackbar(UpdateComplaint));
