// External Imports
import React, { Component } from 'react';
import { Field } from 'react-final-form';
// External Components
import { TextField } from 'final-form-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
// Internal Imports
import FormWrapper from '../../../common/formWrapper';
import FormError from '../../../common/formError';
import {
  composeValidators,
  email,
  required
} from '../../../utility/validators';
import PhoneField from '../../../common/phoneField';
import InputLabel from '@material-ui/core/InputLabel';

const styles = () => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20
  },
  formWrapper: {
    marginLeft: 0
  }
});

class Form extends Component {
  render() {
    const {
      classes,
      onSubmit,
      submitting,
      error,
    } = this.props;

    return (
      <FormWrapper size="large" style={{marginLeft: '0px'}}>
        <form onSubmit={onSubmit}>
          <h1>Invite Form</h1>
          <Grid spacing={2} container alignItems="center" justify="center">
            <Grid item xs={8}>
              <Field
                label="First Name"
                name="firstName"
                fullWidth
                component={TextField}
                placeholder="First Name"
                validate={required}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                label="Middle Name"
                name="middleName"
                fullWidth
                component={TextField}
                placeholder="Middle Name"
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                label="Last Name"
                name="lastName"
                fullWidth
                component={TextField}
                placeholder="Last Name"
                validate={required}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                label="Title"
                name="title"
                fullWidth
                component={TextField}
                placeholder="Title"
                validate={required}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                label="Email"
                name="email"
                fullWidth
                component={TextField}
                validate={composeValidators(email, required)}
              />
            </Grid>
            <Grid item xs={7}>
              <InputLabel>Mobile Phone</InputLabel>
              <Field
                label="Phone Number"
                fullWidth
                name="mobilePhone"
                component={PhoneField}

              />
            </Grid>
            <Grid item xs={7}>
              <InputLabel>Office Phone</InputLabel>
              <Field
                label="Phone Number"
                fullWidth
                name="officePhone"
                component={PhoneField}

              />
            </Grid>
            {error && (
              <Grid item xs={8}>
                <FormError errorText={error} />
              </Grid>
            )}
          </Grid>
          <div className={classes.formButtonWrapper}>
            <Button type="submit" disabled={submitting}>
              Submit
            </Button>
          </div>
        </form>
      </FormWrapper>
    );
  }
}
Form.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  companies: PropTypes.array,
  error: PropTypes.object,
  form: PropTypes.object
};
export default withStyles(styles)(Form);
