// External Imports
import React, { Component } from 'react';
// External Components
import { TextField, DatePicker } from 'mui-rff';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
// Internal Imports
import FormWrapper from '../../../common/formWrapper';
import FormError from '../../../common/formError';
import {
  required
} from '../../../utility/validators';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

const styles = () => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20
  }
});

class Form extends Component {
  render() {
    const {
      classes,
      onSubmit,
      pristine,
      reset,
      submitting,
      error,
      form
    } = this.props;

    return (
      <FormWrapper size="large">
        <form onSubmit={onSubmit} className={classes.formWrapper}>
          <h1>Resident</h1>
          <Grid spacing={2} container alignItems="center" justify="center">
            <Grid item xs={8}>
              <TextField
                label="First Name"
                name="firstName"
                validate={required}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Last Name"
                name="lastName"
                validate={required}
              />
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                label="Lease Start"
                name="leaseStart"
                validate={required}
                dateFunsUtils={DateFnsUtils}
              />
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                label="Lease End"
                name="leaseEnd"
                validate={required}
                dateFunsUtils={DateFnsUtils}
              />
            </Grid>
            {error && (
              <Grid item xs={8}>
                <FormError errorText={error} />
              </Grid>
            )}
            {/* <Grid item xs={8}>
              <FormControlLabel
                control={<Field name="agreeToTerms" component={Checkbox} />}
                label="Agree to terms?"
              />
            </Grid> */}
          </Grid>
          <div className={classes.formButtonWrapper}>
            <Button disabled={pristine || submitting} onClick={reset}>
              Clear Values
            </Button>
            <Button type="submit" disabled={submitting}>
              Submit
            </Button>
          </div>
        </form>
      </FormWrapper>
    );
  }
}
Form.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  companies: PropTypes.array,
  error: PropTypes.object,
  form: PropTypes.object,
  offices: PropTypes.array
};
export default withStyles(styles)(Form);
