import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import NewFolderForm from "./newFolderForm";
import { useQuery, useMutation, gql } from "@apollo/client";
import { Form } from 'react-final-form';

const CREATE_FOLDER = gql`
  mutation createFolder(
    $name: String!
  ) {
    createFolder(
      name: $name
    ) {
      success
      message
    }
  }
`;
const TAGS = gql`
  query tags {
    tags {
      id
      name
    }
  }
`;

const USERS = gql`
  query companyUsers {
    companyUsers {
        id
        firstName
        middleName
        lastName
    }
  }
`;

export default function FormDialog({ isOpen, close, refetch, enqueueSnackbar }) {
  const createFolderCompleted = (data) => {
    refetch();
    enqueueSnackbar(data.createFolder.message, {
      variant: data.createFolder.success ? "success" : "error",
    });
  };
  const createFolderFailed = (data) => {
    enqueueSnackbar(data.createFolder.message, {
      variant: "error",
    });
  };

  const { loading: loadingUsers, data: usersData } = useQuery(USERS);
  const { loading: loadingTags, data: tagsData } = useQuery(TAGS);

  const [createFolder] = useMutation(CREATE_FOLDER, {
    onCompleted: (data) => createFolderCompleted(data),
    onError: (data) => createFolderFailed(data),
  });
  const handleFormSubmit = (values) => (
    createFolder({
      variables: {
        name: values.name,
      },
    })
  )
  return (
    <Dialog open={isOpen} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">New Folder</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={values =>
            handleFormSubmit(
              values
            )
          }
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {(!loadingUsers && !loadingTags) && <NewFolderForm users={usersData.users} tags={tagsData.tags}/>}
            </form>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button onClick={close} color="primary">
          Subscribe
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FormDialog.propTypes = {
  isOpen: PropTypes.boolean,
  close: PropTypes.func,
  refetch: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
};
