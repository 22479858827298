// External Imports
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useMutation, gql } from "@apollo/client";
import _ from "lodash";
import { withSnackbar } from "notistack";
// Internal Imports

const DELETE_BUILDING = gql`
  mutation deleteBuilding($id: ID!) {
    deleteBuilding(id: $id) {
      success
      message
    }
  }
`;

const styles = (theme) => ({
  table: {
    marginTop: theme.spacing(1),
  },
  buttonContent: {
    margin: theme.spacing(1),
  },
});

const addressString = (property) => {
  let string = "";
  string += `${property.address1}, `;
  if (property.address2) string += `${property.address2}, `;
  string += `${property.city}, `;
  string += `${property.state}`;
  return string;
};

function Buildings(props) {
  const history = props.history;
  const deleteBuildingCompleted = async (data) => {
    props.enqueueSnackbar(data.deleteBuilding.message, {
      variant: data.deleteBuilding.success ? "success" : "error",
    });
    props.refetchBuildings();
  };
  const deleteBuildingFailed = (data) => {
    props.enqueueSnackbar(data.deleteBuilding.message, {
      variant: "error",
    });
  };
  const [deleteBuilding] = useMutation(DELETE_BUILDING, {
    onCompleted: (data) => deleteBuildingCompleted(data),
    onError: (data) => deleteBuildingFailed(data),
  });
  const buildings = _.cloneDeep(props.buildings);
  return (
    <MaterialTable
      title="Buildings"
      style={{ marginTop: "32px" }}
      onRowClick={(event, rowData) => {
        event.preventDefault();
        history.push(`/building/${rowData.id}`);
      }}
      actions={[
        {
          icon: "delete",
          tooltip: "Delete Property",
          onClick: (event, rowData) => {
            event.preventDefault();
            deleteBuilding({ variables: { id: rowData.id } });
          },
        },
      ]}
      columns={[
        { title: "Type", field: "type" },
        { title: "Address", render: (rowData) => addressString(rowData) },
      ]}
      data={buildings}
    />
  );
}

Buildings.propTypes = {
  classes: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  refetchBuildings: PropTypes.func,
  buildings: PropTypes.array,
  history: PropTypes.object,
};

export default withStyles(styles)(withSnackbar(Buildings));
