// External Imports
import React, { Component } from 'react';
import { Field } from 'react-final-form';
// External Components
import { TextField, Select } from 'final-form-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
// Internal Imports
import FormWrapper from '../../../common/formWrapper';
import FormError from '../../../common/formError';
import {
  composeValidators,
  website,
  required
} from '../../../utility/validators';
import AddressField from '../../../common/addressAutocomplete';
import PhoneField from '../../../common/phoneField';
import InputLabel from '@material-ui/core/InputLabel';

const styles = () => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20
  }
});

class Form extends Component {
  render() {
    const {
      classes,
      onSubmit,
      pristine,
      reset,
      submitting,
      error,
      form,
      users
    } = this.props;

    return (
      <FormWrapper size="large">
        <form onSubmit={onSubmit} className={classes.formWrapper}>
          <h1>Create Office</h1>
          <Grid spacing={2} container alignItems="center" justify="center">
            <Grid item xs={8}>
              <Field
                label="Office Name"
                name="name"
                fullWidth
                component={TextField}
                placeholder="Office Name"
                validate={required}
              />
            </Grid>
            <Grid item xs={8}>

              <Field
                name="principalId"
                component={Select}
                label="Select Principal"
                formControlProps={{ fullWidth: true }}
                validate={required}
              >
                {users.map(user => (
                  <MenuItem key={user.id} value={user.id}>
                    {`${user.firstName} ${user.lastName} (${user.title})`}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={8}>
              <AddressField
                form={form}
                required={true}
                fieldDefinition={{
                  address1: 'address1',
                  city: 'city',
                  state: 'state',
                  zip: 'zip',
                  placeId: 'placeId'
                }} />
            </Grid>
            <Grid item xs={8}>
              <Field
                label="Suite / Unit Number"
                name="address2"
                fullWidth
                component={TextField}
                placeholder="Suite / Unit Number"
              />
            </Grid>
            <Grid item xs={7}>
              <InputLabel>Phone Number</InputLabel>
              <Field
                label="Phone Number"
                fullWidth
                name="phone"
                component={PhoneField}
                validate={composeValidators(required)}

              />
            </Grid>
            <Grid item xs={8}>
              <Field
                label="Website"
                name="website"
                fullWidth
                component={TextField}
                validate={composeValidators(website, required)}
              />
            </Grid>
            {error && (
              <Grid item xs={8}>
                <FormError errorText={error} />
              </Grid>
            )}
            {/* <Grid item xs={8}>
              <FormControlLabel
                control={<Field name="agreeToTerms" component={Checkbox} />}
                label="Agree to terms?"
              />
            </Grid> */}
          </Grid>
          <div className={classes.formButtonWrapper}>
            <Button disabled={pristine || submitting} onClick={reset}>
              Clear Values
            </Button>
            <Button type="submit" disabled={submitting}>
              Submit
            </Button>
          </div>
        </form>
      </FormWrapper>
    );
  }
}
Form.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  companies: PropTypes.array,
  error: PropTypes.object,
  form: PropTypes.object,
  users: PropTypes.array
};
export default withStyles(styles)(Form);
