import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useQuery, gql } from '@apollo/client';
import { withSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../common/TabPanel';
import Staff from './staff';
import Loading from '../../../common/loading';
const styles = theme => ({
    root: {
        flexGrow: 1
    },
    main: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        marginLeft: theme.spacing(0.5)
    }
});

const OFFICES = gql`
  query companyOffices {
    companyOffices {
        id
        name
        officePermissions {
            id
            user {
                id
                firstName
                lastName
                title
                email
            }
        }
    }
  }
`;

const createOfficeTabs = (offices) => (
    offices.map((office, index) => <Tab key={index} label={office.name} value={office.id} />)
)
const createOfficePanels = (offices, value, refetch) => (
    offices.map((office, index) =>
        <TabPanel key={index} value={value} index={office.id}>
            <Staff office={office} refetch={refetch}/>;
        </TabPanel>
    )
)


function ManageStaff(props) {
    const [state, setState] = useState({
        value: 'company'
    });

    const handleChange = (event, value) => {
        setState({ value });
    };

    const { classes } = props;
    const { loading, data, refetch } = useQuery(OFFICES, {
        fetchPolicy: "network-only"
    });
    if (loading)
        return <Loading />
    const offices = data.companyOffices
    return (
        <main className={classes.main}>
            <Paper className={classes.root}>
                <Tabs
                    value={state.value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    {createOfficeTabs(offices)}
                </Tabs>
            </Paper>
            {createOfficePanels(offices, state.value, refetch)}
        </main>
    );
}
ManageStaff.dispalyName = "ManageStaff"
ManageStaff.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(ManageStaff));
