import React from "react";
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import Loading from "../common/loading";
import { useQuery, useMutation, gql } from "@apollo/client";
import _ from "lodash";
import NewFolder from "./newFolder";
import NewFolderItem from "./newFolderItem";

const styles = (theme) => ({
  formButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    margin: 20,
  },
  wrapper: {
    marginTop: theme.spacing(),
    width: "90%",
    marginLeft: theme.spacing(),
  },
});

const ROOT = gql`
  query root {
    root {
      id
      name
      folderItems {
        id
        name
        link
        type
        tags {
          id
          name
        }
      }
      folders {
        id
        name
        tags {
          id
          name
        }
      }
      tags {
        id
        name
      }
    }
  }
`;

const CREATE_FOLDER = gql`
  mutation createFolder($name: String!) {
    createFolder(name: $name) {
      success
      message
    }
  }
`;

const DELETE_FOLDER = gql`
  mutation deleteFolder($id: ID!) {
    deleteFolder(id: $id) {
      success
      message
    }
  }
`;
const renderTable = (
  props,
  tableState,
  setTableState,
  refetch,
  createFolder,
  deleteFolder,
  folder,
  setOpenNewFolder,
  setOpenNewFolderItem
) => {
  return (
    <MaterialTable
      title="Home Directory"
      columns={tableState.columns}
      data={folder}
      options={{
        addRowPosition: "first",
        pageSizeOptions: [5, 10, 20],
      }}
      actions={[
        {
          icon: "create_new_folder",
          tooltip: "Add Folder",
          isFreeAction: true,
          onClick: () => setOpenNewFolder(true),
        },
        {
          icon: "note_add",
          tooltip: "Add Folder",
          isFreeAction: true,
          onClick: () => setOpenNewFolderItem(true),
        },
      ]}
    />
  );
};
const Root = (props) => {
  const { loading, refetch, data } = useQuery(ROOT);
  const deleteFolderCompleted = (data) => {
    refetch();
    props.enqueueSnackbar(data.deleteFolder.message, {
      variant: data.deleteFolder.success ? "success" : "error",
    });
  };
  const deleteFolderFailed = (data) => {
    props.enqueueSnackbar(data.deleteFolder.message, {
      variant: "error",
    });
  };
  const createFolderCompleted = (data) => {
    refetch();
    props.enqueueSnackbar(data.createFolder.message, {
      variant: data.createFolder.success ? "success" : "error",
    });
  };
  const createFolderFailed = (data) => {
    props.enqueueSnackbar(data.createFolder.message, {
      variant: "error",
    });
  };
  const [deleteFolder] = useMutation(DELETE_FOLDER, {
    onCompleted: (data) => deleteFolderCompleted(data),
    onError: (data) => deleteFolderFailed(data),
  });
  const [createFolder] = useMutation(CREATE_FOLDER, {
    onCompleted: (data) => createFolderCompleted(data),
    onError: (data) => createFolderFailed(data),
  });
  const [openNewFolder, setOpenNewFolder] = React.useState(false);
  const [openNewFolderItem, setOpenNewFolderItem] = React.useState(false);
  const [tableState, setTableState] = React.useState({
    columns: [{ title: "Name", field: "name" }],
  });

  if (loading) {
    return <Loading />;
  } else {
    const folder = _.cloneDeep(data.folder);
    return (
      <div>
        <NewFolder
          parentId={null}
          refetch={refetch}
          isOpen={openNewFolder}
          close={() => {
            setOpenNewFolder(false);
          }}
        />
        <NewFolderItem
          parentId={null}
          refetch={refetch}
          isOpen={openNewFolderItem}
          close={() => {
            setOpenNewFolderItem(false);
          }}
        />
        {renderTable(
          props,
          tableState,
          setTableState,
          refetch,
          createFolder,
          deleteFolder,
          folder,
          setOpenNewFolder,
          setOpenNewFolderItem
        )}
      </div>
    );
  }
};
Root.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(Root));
