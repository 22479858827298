import React, { useRef } from 'react';
import S3 from 'react-aws-s3';
import InputLabel from '@material-ui/core/InputLabel';
import { withSnackbar } from "notistack";
// import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
};
const ReactS3Client = new S3(config);

function upload(props) {
    const fileInput = useRef();
    const handleClick = async (event) => {
        event.preventDefault();
        if (!fileInput || !fileInput.current || !fileInput.current.files[0]) {
            props.enqueueSnackbar('Select Document to Upload', {
                variant: 'warning'
            });
            return
        }
        let file = fileInput.current.files[0];
        let fileName = file.name;
        ReactS3Client.uploadFile(file, fileName)
            .then(data => {
                if (data.status === 204) {
                    console.log("Success")
                    props.enqueueSnackbar('Document Upload Successful', {
                        variant: 'error'
                    });
                } else {
                    console.log("Fail")
                    props.enqueueSnackbar('Document Failed to Upload', {
                        variant: 'error'
                    });
                }
            })
            .catch(error => {
                console.log(error)
                props.enqueueSnackbar('Document Failed to Upload', {
                    variant: 'error'
                });
            })
    }
    return (
        <form onSubmit={handleClick}>
            <InputLabel >{`${props.uploadLabel}`}</InputLabel>
            <input type={"file"} ref={fileInput} />
            <Button type="submit">Upload</Button>
        </form>
    )
}

export default withSnackbar(upload);