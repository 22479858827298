// External Imports
import React from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { useQuery, useMutation, gql } from '@apollo/client';
import _ from 'lodash';
// Internal Imports
import Loading from '../../common/loading';

const DELETE_COMPANY = gql`
  mutation deleteCompany(
    $id: ID!
  ) {
    deleteCompany(
      id: $id
    ) {
      success
      message
    }
  }
`;

const COMPANIES = gql`
  query companies {
    companies {
        id
        name
        website
        phone
        address1
        address2
        city
        state
        zip
        offices {
            id
            name
        }
    }
  }
`;

const styles = theme => ({
    table: {
        marginTop: theme.spacing(1)
    },
    buttonContent: {
        margin: theme.spacing(1)
    },
});

const officeString = offices => {
    let officeString = ''
    let officeLength = offices.length
    offices.forEach((office, index) => {
        officeString += office.name
        if (index < officeLength)
            officeString += ', '
    })
    return officeString
}
const addressString = company => {
    let addressString = ''
    addressString += company.address1
    if(company.address2)
        addressString += `, ${company.address2}`
    addressString += `, ${company.city}`
    addressString += `, ${company.state}`
    addressString += `, ${company.zip}`
    return addressString
}

function Users(props) {
    const deletUserCompleted = data => {
        props.enqueueSnackbar(data.deleteCompany.message, {
            variant: data.deleteCompany.success ? 'success' : 'error'
        });
    }
    const deleteCompanyFailed = data => {
        props.enqueueSnackbar(data.deleteCompany.message, {
            variant: 'error'
        });
    }
    const [deleteCompany] = useMutation(DELETE_COMPANY, {
        onCompleted: data => deletUserCompleted(data),
        onError: data => deleteCompanyFailed(data)
    });
    const { loading, data } = useQuery(COMPANIES);
    if (loading) {
        return <Loading />
    } else {
        const companies = _.cloneDeep(data.companies);
        return (
            <MaterialTable
                title="Companies"
                style={{paddingLeft: '0px', marginTop: '32px'}}
                actions={[
                    {
                        icon: 'delete',
                        tooltip: 'Delete Company',
                        onClick: (event, rowData) => {
                            event.preventDefault()
                            deleteCompany({ variables: { id: rowData.id } })
                        }
                    }
                ]}
                columns={[
                    { title: "Name", field: "name" },
                    { title: "Address", render: rowData =>  addressString(rowData) },
                    { title: "Phone", field: "phone" },
                    { title: "Website", field: "website" },
                    { title: "Offices", render: rowData =>  officeString(rowData.offices)},
                ]}
                data={companies}
            />
        )
    }
}

Users.propTypes = {
    classes: PropTypes.object,
    enqueueSnackbar: PropTypes.func
}

export default withStyles(styles)(Users);
