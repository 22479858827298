import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";
import { withSnackbar } from "notistack";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Typography from "@material-ui/core/Typography";
import TabPanel from "../../common/TabPanel";
import Loading from "../../common/loading";
import AddResident from "./addResident";
import Residents from "./residents";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  breadcrumbs: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  main: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
});

const RESIDENTS = gql`
  query unitResidents($unitId: ID!) {
    unitResidents(unitId: $unitId) {
      id
      firstName
      lastName
      status
      leaseStart
      leaseEnd
      initialContactDate
      initialContact
      followUpContactDate
      followUpContact
      interviewedDate
      interviewed
      qualifiedDate
      qualified
      unit {
        id
        identifier
        building {
          id
          property {
            id
            name
          }
        }
      }
    }
  }
`;
const UNIT = gql`
  query unit($id: ID!) {
    unit(id: $id) {
      id
      identifier
      building {
        id
        type
        property {
          id
          name
        }
      }
    }
  }
`;

function Units(props) {
  const [state, setState] = useState({
    value: "resident",
  });

  const handleChange = (event, value) => {
    setState({ value });
  };

  const { classes, match, history } = props;
  const unitId = match.params.id;
  const { loading: residentsLoading, data: residentsData, refetch } = useQuery(
    RESIDENTS,
    {
      variables: { unitId },
      fetchPolicy: "network-only",
    }
  );
  const { loading: unitLoading, data: unitData } = useQuery(UNIT, {
    variables: { id: unitId },
    fetchPolicy: "network-only",
  });
  if (residentsLoading || unitLoading) return <Loading />;
  const residents = residentsData.unitResidents;
  const unit = unitData.unit;
  const building = unit.building;
  const property = building.property;
  return (
    <main className={classes.main}>
      <Paper className={classes.breadcrumbs}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            color="inherit"
            href={`/property/${property.id}`}
            onClick={(event) => {
              event.preventDefault();
              history.push(`/property/${property.id}`);
            }}
          >
            {`Property (${property.name})`}
          </Link>
          <Link
            color="inherit"
            href={`/building/${building.id}`}
            onClick={(event) => {
              event.preventDefault();
              history.push(`/building/${building.id}`);
            }}
          >
            Building
          </Link>
          <Typography color="textPrimary">{`Unit (${unit.identifier})`}</Typography>
        </Breadcrumbs>
      </Paper>
      <Paper className={classes.root}>
        <Tabs
          value={state.value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab key={"resident"} label={"Residents"} value={"resident"} />
          <Tab
            key={"addResident"}
            label={"Add Resident"}
            value={"addResident"}
          />
        </Tabs>
      </Paper>
      <TabPanel key={"resident"} value={state.value} index={"resident"}>
        <Residents
          residents={residents}
          unitId={unitId}
          history={history}
          refetchResidents={refetch}
        />
      </TabPanel>
      <TabPanel key={"addResident"} value={state.value} index={"addResident"}>
        <AddResident unitId={unitId} refetchResidents={refetch} />
      </TabPanel>
    </main>
  );
}
Units.dispalyName = "Units";
Units.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withSnackbar(withStyles(styles)(Units));
