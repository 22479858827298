import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Work from '@material-ui/icons/Work';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Company from '@material-ui/icons/Domain';
import Users from '@material-ui/icons/PeopleOutline';
import Role from '@material-ui/icons/PeopleOutline';

// Internal
import DrawerItem from './drawerItem';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
});

class ManagementList extends React.Component {
  state = { open: false };

  handleClick = () => {
    this.props.toggleDrawerList('management');
  };

  render() {
    const { classes, drawerLists } = this.props;
    const open = drawerLists['management'];
    return (
      <div className={classes.root}>
        <List
          component="nav"
        // subheader={<ListSubheader component="div">Nested List Items</ListSubheader>}
        >
          <ListItem button onClick={this.handleClick}>
            <ListItemIcon>
              <Work />
            </ListItemIcon>
            <ListItemText primary="Management" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <DrawerItem
                linkTo="/manage-users"
                Icon={Users}
                text="Manage Users"
              />
              <DrawerItem
                linkTo="/manage-office"
                Icon={Company}
                text="Manage Offices"
              />
              <DrawerItem
                linkTo="/role-management"
                Icon={Role}
                text="Role Management"
              />
            </List>
          </Collapse>
        </List>
      </div>
    );
  }
}

ManagementList.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerList: PropTypes.func,
  drawerLists: PropTypes.element,
  auth: PropTypes.object,
};

export default withStyles(styles)(ManagementList);
