import React from 'react';
import MaterialTable from 'material-table';
import { useMutation, gql } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import officePermissions from '../officePermissions';

const styles = theme => ({
    wrapper: {
        marginTop: theme.spacing(),
        width: '90%'
    }
});

const UPDATE_OFFICE_PERMISSION = gql`
  mutation updateOfficePermission($id: ID!, $permission: String!) {
    updateOfficePermission(id: $id, permission: $permission) {
        success
        message
     }
  } 
`;


const permissionToggle = (data, field, updateOfficePermission) => {
    return (
        <Checkbox
            key={`${field}-${data.id}`}
            checked={!!data.officePermissionSet[field]}
            onChange={() => {
                updateOfficePermission({variables: {id: data.id, permission: field}});
            }}
        />
    );
};

function CompanyRoles(props) {
    const updateOfficePermissionCompleted = data => {
        props.enqueueSnackbar(data.updateOfficePermission.message, {
            variant: data.updateOfficePermission.success ? 'success' : 'error'
        });
        props.refetch()
    }
    const updateOfficePermissionFailed = () => {
        props.enqueueSnackbar("Update Permission Failed", {
            variant: 'error'
        });
    }
    const [updateOfficePermission] = useMutation(UPDATE_OFFICE_PERMISSION, {
        onCompleted: data => updateOfficePermissionCompleted(data),
        onError: data => updateOfficePermissionFailed(data)
    });
    let columns = [
        {
            title: 'First Name',
            field: 'user.firstName'
        },
        {
            title: 'Last Name',
            field: 'user.lastName'
        },
    ];
    let permissionColumns = officePermissions.map((officePermission) => {
        return {
            title: officePermission.label,
            field: officePermission.value,
            render: rowData => permissionToggle(rowData, officePermission.value, updateOfficePermission)
        }
    })
    columns = [...columns, ...permissionColumns]
    const users = _.cloneDeep(props.office.officePermissions);
    return (
        <MaterialTable
            style={{marginTop: '16px'}}
            title="Office Permissions"
            columns={columns}
            data={users}
            options={{
                paging: false,
                maxBodyHeight: '1000px'
            }}
        />
    );
}
CompanyRoles.propTypes = {
    classes: PropTypes.object,
    enqueueSnackbar: PropTypes.func,
    office: PropTypes.object,
    refetch: PropTypes.func
};

export default withStyles(styles)(withSnackbar(CompanyRoles));
