// External Imports
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// Internal
import SignInForm from './form';

const SIGNIN = gql`
  mutation signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      success
      message
      data
    }
  }
`;
function SignIn(props) {
  const { refetchAuth } = props;
  const mutationCompleted = async (data) => {
    props.enqueueSnackbar(data.signin.message, {
      variant: data.signin.success ? 'success' : 'error'
    });
    if(data.signin.success){
      localStorage.setItem('token', data.signin.data);
      await refetchAuth()
      props.history.push('/dashboard');
    }
  }
  const mutationFailed = () => {
    props.enqueueSnackbar("Sign In Failed", {
      variant: 'error'
    });
  }
  const [signin] = useMutation(SIGNIN, {
    onCompleted: data => mutationCompleted(data),
    onError: data => mutationFailed(data)
  });
  
  const handleFormSubmit = (values, signin) => {
    let { email, password } = values;
    email = email.toLowerCase();
    signin({ variables: { email, password } });
  };
  return <Form
    onSubmit={values => handleFormSubmit(values, signin)}
    render={({
      handleSubmit,
    }) => (
      <SignInForm
        onSubmit={handleSubmit}
        history={props.history}
      />
    )}
  />

}

SignIn.propTypes = {
  history: PropTypes.object,
  refetchAuth: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
}

export default withSnackbar(withRouter(SignIn));
