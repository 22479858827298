// External Imports
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Form } from "react-final-form";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import setFieldData from "final-form-set-field-data";
import { withSnackbar } from "notistack";
// Internal
import CreateUnitForm from "./form";

const CREATE_UNIT = gql`
  mutation createUnit($buildingId: ID!, $identifier: String!) {
    createUnit(buildingId: $buildingId, identifier: $identifier) {
      success
      message
    }
  }
`;

function CreateUnit(props) {
  const buildingId = props.buildingId;

  const mutationCompleted = async (data) => {
    props.enqueueSnackbar(data.createUnit.message, {
      variant: data.createUnit.success ? "success" : "error",
    });
    await props.refetchUnits();
  };
  const mutationFailed = () => {
    props.enqueueSnackbar("Create Company Failed", {
      variant: "error",
    });
  };
  const [createUnit] = useMutation(CREATE_UNIT, {
    onCompleted: (data) => mutationCompleted(data),
    onError: (data) => mutationFailed(data),
  });

  const handleFormSubmit = (values, createUnit, buildingId) => {
    console.log("BULDING ID", buildingId);
    console.log("Values", values);
    createUnit({
      variables: {
        buildingId,
        ...values,
      },
    });
  };
  return (
    <Form
      onSubmit={(values) => handleFormSubmit(values, createUnit, buildingId)}
      mutators={{
        setFieldData,
        setValue: (args, state, utils) => {
          utils.changeValue(state, args[0], () => args[1]);
        },
      }}
      render={({ handleSubmit, form }) => (
        <CreateUnitForm onSubmit={handleSubmit} form={form} />
      )}
    />
  );
}

CreateUnit.propTypes = {
  refetchAuth: PropTypes.func,
  refetchUnits: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  buildingId: PropTypes.string,
};
export default withRouter(withSnackbar(CreateUnit));
