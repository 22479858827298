// External Imports
import React, { Component } from 'react';
import { Field } from 'react-final-form';
// External Components
import { TextField, DatePicker, Select } from 'mui-rff';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
// Internal Imports
import FormWrapper from '../../common/formWrapper';
import FormError from '../../common/formError';
import {
  composeValidators,
  website,
  required
} from '../../utility/validators';
import AddressField from '../../common/addressAutocomplete';
import PhoneField from '../../common/phoneField';
import InputLabel from '@material-ui/core/InputLabel';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

const styles = () => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20
  }
});

class Form extends Component {
  render() {
    const {
      offices,
      classes,
      onSubmit,
      pristine,
      reset,
      submitting,
      error,
      form
    } = this.props;

    return (
      <FormWrapper size="large">
        <form onSubmit={onSubmit} className={classes.formWrapper}>
          <h1>Add Property</h1>
          <Grid spacing={2} container alignItems="center" justify="center">
            <Grid item xs={8}>
              <Select label="Select Office" name="officeId">
                {offices.map(office => (
                  <MenuItem key={office.id} value={office.id}>
                    {office.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Property Name"
                name="name"
                validate={required}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Owner"
                name="owner"
                validate={required}
              />
            </Grid>
            <Grid item xs={8}>
              <AddressField
                form={form}
                required={true}
                fieldDefinition={{
                  fieldName: 'formattedAddress',
                  address1: 'address1',
                  city: 'city',
                  state: 'state',
                  zip: 'zip',
                  placeId: 'placeId'
                }} />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Suite / Unit Number"
                name="address2"
              />
            </Grid>
            <Grid item xs={8}>
              <AddressField
                form={form}
                required={true}
                label={"Jurisdiction Address"}
                fieldDefinition={{
                  fieldName: 'formattedJurisdictionAddress',
                  address1: 'jurisdictionAddress1',
                  city: 'jurisdictionCity',
                  state: 'jurisdictionState',
                  zip: 'jurisdictionZip',
                  placeId: 'jurisdictionPlaceId'
                }} />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Jurisdiction Suite / Unit Number"
                name="jurisdictionAddress2"
              />
            </Grid>
            <Grid item xs={7}>
              <InputLabel>Phone Number</InputLabel>
              <Field
                label="Phone Number"
                fullWidth
                name="phone"
                component={PhoneField}
                validate={composeValidators(required)}

              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Website"
                name="website"
                validate={composeValidators(website, required)}
              />
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                label="Date of Construction"
                name="dateOfConstruction"
                validate={required}
                dateFunsUtils={DateFnsUtils}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Number of Units"
                name="numberOfUnits"
                validate={required}
              />
            </Grid>
            {error && (
              <Grid item xs={8}>
                <FormError errorText={error} />
              </Grid>
            )}
            {/* <Grid item xs={8}>
              <FormControlLabel
                control={<Field name="agreeToTerms" component={Checkbox} />}
                label="Agree to terms?"
              />
            </Grid> */}
          </Grid>
          <div className={classes.formButtonWrapper}>
            <Button disabled={pristine || submitting} onClick={reset}>
              Clear Values
            </Button>
            <Button type="submit" disabled={submitting}>
              Submit
            </Button>
          </div>
        </form>
      </FormWrapper>
    );
  }
}
Form.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  companies: PropTypes.array,
  error: PropTypes.object,
  form: PropTypes.object,
  offices: PropTypes.array
};
export default withStyles(styles)(Form);
