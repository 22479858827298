// External Imports
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useMutation, gql } from "@apollo/client";
import _ from "lodash";
import { withSnackbar } from "notistack";
// Internal Imports

const DELETE_DOCUMENT = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      success
      message
    }
  }
`;

const styles = (theme) => ({
  table: {
    marginTop: theme.spacing(1),
  },
  buttonContent: {
    margin: theme.spacing(1),
  },
});

function Documents(props) {
  const deleteDocumentCompleted = async (data) => {
    props.enqueueSnackbar(data.deleteDocument.message, {
      variant: data.deleteDocument.success ? "success" : "error",
    });
    await props.refetchDocuments();
  };
  const deleteDocumentFailed = (data) => {
    props.enqueueSnackbar(data.deleteDocument.message, {
      variant: "error",
    });
  };
  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    onCompleted: (data) => deleteDocumentCompleted(data),
    onError: (data) => deleteDocumentFailed(data),
  });
  const Documents = _.cloneDeep(props.Documents);
  return (
    <MaterialTable
      title="Documents"
      style={{ marginTop: "32px" }}
      actions={[
        {
          icon: "delete",
          tooltip: "Delete Resident",
          onClick: (event, rowData) => {
            event.preventDefault();
            deleteDocument({ variables: { id: rowData.id } });
          },
        },
      ]}
      columns={[
        { title: "Name", field: "name" },
        { title: "Type", field: "type" },
        { title: "Download", field: "link" },
      ]}
      data={Documents}
    />
  );
}

Documents.propTypes = {
  classes: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  refetchDocuments: PropTypes.func,
  Documents: PropTypes.array,
  history: PropTypes.object,
};

export default withStyles(styles)(withSnackbar(Documents));
