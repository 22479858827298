import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";
import { withSnackbar } from "notistack";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Typography from "@material-ui/core/Typography";
import TabPanel from "../../common/TabPanel";
import Loading from "../../common/loading";
import EditResident from "./editResident";
import Complaint from "./complaint";
import ComplaintDocuments from "./complaint/documents";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  breadcrumbs: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  main: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
});

const RESIDENT = gql`
  query resident($id: ID!) {
    resident(id: $id) {
      id
      firstName
      lastName
      status
      leaseStart
      leaseEnd
      initialContactDate
      initialContact
      followUpContactDate
      followUpContact
      interviewedDate
      interviewed
      qualifiedDate
      qualified
      complaint {
        id
        number
        filingDate
        lease {
          id
          name
          type
          link
        }
        disclosureForm {
          id
          name
          type
          link
        }
        agentCompliance {
          id
          name
          type
          link
        }
        disclosureFormReviewed
        agentComplianceReviewed
        relevantDocumentsReviewed
        parcheReport {
          id
          name
          type
          link
        }
        attorneys {
          id
          firstName
          lastName
        }
        paralegals {
          id
          firstName
          lastName
        }
        documents {
          id
          name
          type
          link
        }
      }
      unit {
        id
        identifier
        building {
          id
          property {
            id
            name
          }
        }
      }
    }
  }
`;

function Resident(props) {
  const [state, setState] = useState({
    value: "resident",
  });

  const handleChange = (event, value) => {
    setState({ value });
  };

  const { classes, match } = props;
  const id = match.params.id;
  const { loading, data, refetch } = useQuery(RESIDENT, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  if (loading) return <Loading />;
  const resident = data.resident;
  const unit = resident.unit;
  const building = unit.building;
  const property = building.property;
  return (
    <main className={classes.main}>
      <Paper className={classes.breadcrumbs}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            color="inherit"
            href={`/property/${property.id}`}
            onClick={(event) => {
              event.preventDefault();
              props.history.push(`/property/${property.id}`);
            }}
          >
            {`Property (${property.name})`}
          </Link>
          <Link
            color="inherit"
            href={`/building/${building.id}`}
            onClick={(event) => {
              event.preventDefault();
              props.history.push(`/building/${building.id}`);
            }}
          >
            Building
          </Link>
          <Link
            color="inherit"
            href={`/unit/${unit.id}`}
            onClick={(event) => {
              event.preventDefault();
              props.history.push(`/unit/${unit.id}`);
            }}
          >
            {`Unit (${unit.identifier})`}
          </Link>
          <Typography color="textPrimary">{`Resident: ${resident.firstName} ${resident.lastName}`}</Typography>
        </Breadcrumbs>
      </Paper>
      <Paper className={classes.root}>
        <Tabs
          value={state.value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab key={"resident"} label={"Resident"} value={"resident"} />
          <Tab key={"complaint"} label={"Complaint"} value={"complaint"} />
          <Tab
            key={"complaintDocuments"}
            label={"Complaint Documents"}
            value={"complaintDocuments"}
          />
        </Tabs>
      </Paper>
      <TabPanel key={"resident"} value={state.value} index={"resident"}>
        <EditResident resident={resident} />
      </TabPanel>
      <TabPanel key={"complaint"} value={state.value} index={"complaint"}>
        <Complaint resident={resident} complaint={resident.complaint} refetchResident={refetch}/>
      </TabPanel>
      <TabPanel
        key={"complaintDocuments"}
        value={state.value}
        index={"complaintDocuments"}
      >
        <ComplaintDocuments
          resident={resident}
          complaint={resident.complaint}
        />
      </TabPanel>
    </main>
  );
}
Resident.dispalyName = "Resident";
Resident.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withSnackbar(withStyles(styles)(Resident));
