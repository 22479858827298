import Drawer from "./components/drawer";
import SignUp from "./components/signUp";
import SignIn from "./components/signIn";
import SignOut from "./components/signOut";
import CreateCompany from "./components/createCompany";
import RegisterUser from "./components/registerUser";
import NoMatch from "./components/statusPages/noMatch";
import AppBar from "./components/appBar";
import RoleManagementContainer from "./components/management/roles";
import ManageUsersContainer from "./components/management/users";
import ManageOfficeContainer from "./components/management/office";
import AdminUsersContainer from "./components/admin/users";
import AdminCompanyContainer from "./components/admin/company";
import DashboardContainer from "./components/dashboard";
import DocumentReviewContainer from "./components/documentReview";
import DocumentLibraryContainer from "./components/documentLibrary";
import FolderContainer from "./components/documentLibrary/folder";
import LibrarySettingsContainer from "./components/documentLibrary/settings";
import ResidentsContainer from "./components/program/residents";
import AddPropertyContainer from "./components/program/addProperty";
import PropertiesContainer from "./components/program/properties";
import PropertyContainer from "./components/program/property";
import BuildingContainer from "./components/program/building";
import UnitContainer from "./components/program/unit";
import ResidentContainer from "./components/program/resident";

const AdminCompany = Drawer(AdminCompanyContainer);
const AdminUsers = Drawer(AdminUsersContainer);
const ManageOffice = Drawer(ManageOfficeContainer);
const ManageUsers = Drawer(ManageUsersContainer);
const RoleManagement = Drawer(RoleManagementContainer);
const Dashboard = Drawer(DashboardContainer);
const Residents = Drawer(ResidentsContainer);
const DocumentReview = Drawer(DocumentReviewContainer);
const DocumentLibrary = Drawer(DocumentLibraryContainer);
const Folder = Drawer(FolderContainer);
const LibrarySettings = Drawer(LibrarySettingsContainer);
const AddProperty = Drawer(AddPropertyContainer);
const Properties = Drawer(PropertiesContainer);
const Property = Drawer(PropertyContainer);
const Building = Drawer(BuildingContainer);
const Unit = Drawer(UnitContainer);
const Resident = Drawer(ResidentContainer);

export default {
  SignUp,
  SignIn,
  CreateCompany,
  SignOut,
  NoMatch,
  RegisterUser,
  AppBar,
  Dashboard,
  ManageUsers,
  ManageOffice,
  RoleManagement,
  AdminCompany,
  AdminUsers,
  Residents,
  DocumentReview,
  DocumentLibrary,
  Folder,
  AddProperty,
  Properties,
  LibrarySettings,
  Property,
  Building,
  Unit,
  Resident,
};
