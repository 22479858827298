import React from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import PropTypes from 'prop-types';

export default class AddressAutocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.autocomplete = null;
    }

    render() {
        if (!window.google) return null;
        const onLoad = (props, autocomplete) => {
            autocomplete.addListener(
                'place_changed',
                fillInAddress.bind(this, props)
            );
            this.autocomplete = autocomplete;
        };
        const formatPlace = place => {
            let formattedPlace = {formattedAddress: place.formatted_address, placeId: place.place_id}
            place.address_components.map((component) => {
                formattedPlace[component.types[0]] = component.long_name;
            })
            return formattedPlace
        }
        const fillInAddress = props => {
            let place = formatPlace(this.autocomplete.getPlace())
            props.form.mutators.setValue(props.fieldDefinition.fieldName || 'formattedAddress', place.formattedAddress);
            props.form.mutators.setValue(props.fieldDefinition.address1, `${place.street_number} ${place.route}`);
            props.form.mutators.setValue(props.fieldDefinition.city, place.locality);
            props.form.mutators.setValue(props.fieldDefinition.state, place.administrative_area_level_1);
            props.form.mutators.setValue(props.fieldDefinition.zip, place.postal_code);
            props.form.mutators.setValue(props.fieldDefinition.placeId, place.placeId);
        };
        return (
            <Autocomplete onLoad={onLoad.bind(null, this.props)}>
                <Field
                    label={this.props.label || "Address"}
                    fullWidth
                    name={this.props.fieldDefinition.fieldName || 'formattedAddress'}
                    component={TextField}
                    placeholder={this.props.label || "Address"}
                    required={this.props.required}
                />
            </Autocomplete>
        );
    }
}
AddressAutocomplete.propTypes = {
    required: PropTypes.bool,
    label: PropTypes.string,
    fieldDefinition: PropTypes.object
}