// External Imports
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

class PrivateRoute extends React.Component {
  render() {
    const { Component, auth, path, ...rest } = this.props;
    if (auth)
      return (
        <Route
          path={path} render={props => <Component auth={auth} {...rest} {...props} />}
        />
      );
    else
      return (
        <Redirect
          {...rest}
          to={{ pathname: '/signin', state: { from: this.props.location } }}
        />
      );
  }
}
PrivateRoute.propTypes = {
  path: PropTypes.string,
  auth: PropTypes.bool,
  location: PropTypes.string,
  Component: PropTypes.element
};
export default PrivateRoute;
