import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";
import { withSnackbar } from "notistack";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Typography from "@material-ui/core/Typography";
import TabPanel from "../../common/TabPanel";
import Loading from "../../common/loading";
import AddUnit from "./addUnit";
import Units from "./units";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  breadcrumbs: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  main: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
});

const UNITS = gql`
  query buildingUnits($buildingId: ID!) {
    buildingUnits(buildingId: $buildingId) {
      id
      identifier
      building {
        id
        property {
          id
          name
        }
      }
    }
  }
`;
const BUILDING = gql`
  query building($id: ID!) {
    building(id: $id) {
      id
      property {
        id
        name
      }
    }
  }
`;

function Buildings(props) {
  const [state, setState] = useState({
    value: "unit",
  });

  const handleChange = (event, value) => {
    setState({ value });
  };

  const { classes, match } = props;
  const buildingId = match.params.id;
  const { loading, data, refetch } = useQuery(UNITS, {
    variables: { buildingId },
    fetchPolicy: "network-only",
  });
  const { loading: buildingLoading, data: buildingData } = useQuery(BUILDING, {
    variables: { id: buildingId },
    fetchPolicy: "network-only",
  });
  if (loading || buildingLoading) return <Loading />;
  const units = data.buildingUnits;
  const building = buildingData.building;
  const property = building.property;
  return (
    <main className={classes.main}>
      <Paper className={classes.breadcrumbs}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            color="inherit"
            href={`/property/${property.id}`}
            onClick={(event) => {
              event.preventDefault();
              props.history.push(`/property/${property.id}`);
            }}
          >
            {`Property (${property.name})`}
          </Link>
          <Typography color="textPrimary">{`Building`}</Typography>
        </Breadcrumbs>
      </Paper>
      <Paper className={classes.root}>
        <Tabs
          value={state.value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab key={"unit"} label={"Units"} value={"unit"} />
          <Tab key={"addUnit"} label={"Add Unit"} value={"addUnit"} />
        </Tabs>
      </Paper>
      <TabPanel key={"unit"} value={state.value} index={"unit"}>
        <Units
          units={units}
          buildingId={buildingId}
          history={props.history}
          refetchUnits={refetch}
        />
      </TabPanel>
      <TabPanel key={"addUnit"} value={state.value} index={"addUnit"}>
        <AddUnit
          buildingId={buildingId}
          history={props.history}
          refetchUnits={refetch}
        />
      </TabPanel>
    </main>
  );
}
Buildings.dispalyName = "Buildings";
Buildings.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withSnackbar(withStyles(styles)(Buildings));
