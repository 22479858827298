// External Imports
import React, { Component } from "react";
// External Components
import { TextField, DatePicker } from "mui-rff";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";
// Internal Imports

import FormError from "../../../common/formError";
import { required } from "../../../utility/validators";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

const styles = () => ({
  formButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    margin: 20,
  },
});

class Form extends Component {
  render() {
    const { classes, onSubmit, submitting, error, form } = this.props;

    return (
        <form onSubmit={onSubmit} className={classes.formWrapper}>
          <h1>Complaint</h1>
          <Grid spacing={2} container alignItems="center" justify="center">
            <Grid item xs={8}>
              <TextField
                label="Filing Number"
                name="number"
                validate={required}
              />
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                label="Filing Date"
                name="filingDate"
                validate={required}
                dateFunsUtils={DateFnsUtils}
              />
            </Grid>
            {error && (
              <Grid item xs={8}>
                <FormError errorText={error} />
              </Grid>
            )}
          </Grid>
          <div className={classes.formButtonWrapper}>
            <Button type="submit" disabled={submitting}>
              Update
            </Button>
          </div>
        </form>
    );
  }
}
Form.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  companies: PropTypes.array,
  error: PropTypes.object,
  form: PropTypes.object,
  offices: PropTypes.array,
};
export default withStyles(styles)(Form);
