import React from 'react';
import MaterialTable from 'material-table';
import { useMutation, gql } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import companyPermissions from '../companyPermissions';

const styles = theme => ({
    wrapper: {
        marginTop: theme.spacing(),
        width: '90%'
    }
});

const UPDATE_COMPANY_PERMISSION = gql`
  mutation updateCompanyPermission($id: ID!, $permission: String!) {
    updateCompanyPermission(id: $id, permission: $permission) {
        success
        message
    }
  } 
`;


const permissionToggle = (data, field, updateCompanyPermission) => {
    return (
        <Checkbox
            key={`${field}-${data.id}`}
            checked={!!data.companyPermissionSet[field]}
            onChange={() => {
                updateCompanyPermission({variables: {id: data.id, permission: field}});
            }}
        />
    );
};

function CompanyRoles(props) {
    const updateCompanyPermissionCompleted = data => {
        props.enqueueSnackbar(data.updateCompanyPermission.message, {
            variant: data.updateCompanyPermission.success ? 'success' : 'error'
        });
        props.refetch()
    }
    const updateCompanyPermissionFailed = () => {
        props.enqueueSnackbar("Update Permission Failed", {
            variant: 'error'
        });
    }
    const [updateCompanyPermission] = useMutation(UPDATE_COMPANY_PERMISSION, {
        onCompleted: data => updateCompanyPermissionCompleted(data),
        onError: data => updateCompanyPermissionFailed(data)
    });
    let columns = [
        {
            title: 'First Name',
            field: 'user.firstName'
        },
        {
            title: 'Last Name',
            field: 'user.lastName'
        },
    ];
    let permissionColumns = companyPermissions.map((companyPermission) => {
        return {
            title: companyPermission.label,
            field: companyPermission.value,
            render: rowData => permissionToggle(rowData, companyPermission.value, updateCompanyPermission)
        }
    })
    columns = [...columns, ...permissionColumns]
    const users = _.cloneDeep(props.company.companyPermissions);
    return (
        <MaterialTable
            style={{marginTop: '16px'}}
            title="Company Permissions"
            columns={columns}
            data={users}
            options={{
                paging: false,
                maxBodyHeight: '1000px'
            }}
        />
    );
}
CompanyRoles.propTypes = {
    classes: PropTypes.object,
    enqueueSnackbar: PropTypes.func,
    company: PropTypes.object,
    refetch: PropTypes.func
};

export default withStyles(styles)(withSnackbar(CompanyRoles));
