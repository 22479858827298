import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../common/TabPanel';
import CreateOffice from './createOffice';
import AddStaff from './addStaff';
import Offices from './offices';
import Staff from './staff';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  main: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5)
  }
});


function ManageCompany(props) {
  const [state, setState] = useState({
    value: 'offices'
  });

  const handleChange = (event, value) => {
    setState({ value });
  };

  const { classes } = props;

  return (
    <main className={classes.main}>
      <Paper className={classes.root}>
        <Tabs
          value={state.value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Offices" value="offices" />
          <Tab label="Staff" value="staff" />
          <Tab label="Add Office" value="addOffice" />
          <Tab label="Add Staff to Office" value="addStaff" />
        </Tabs>
      </Paper>
      <TabPanel value={state.value} index="offices">
        <Offices />;
      </TabPanel>
      <TabPanel value={state.value} index="staff">
        <Staff />;
      </TabPanel>
      <TabPanel value={state.value} index="addOffice">
        <CreateOffice />;
      </TabPanel>
      <TabPanel value={state.value} index="addStaff">
        <AddStaff />;
      </TabPanel>
    </main>
  );
}
ManageCompany.dispalyName = "ManageCompany"
ManageCompany.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(ManageCompany));
