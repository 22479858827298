// External Imports
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Form } from "react-final-form";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import setFieldData from "final-form-set-field-data";
import { withSnackbar } from "notistack";
// Internal
import CreateResidentForm from "./form";

const CREATE_RESIDENT = gql`
  mutation createResident(
    $unitId: ID!
    $firstName: String!
    $lastName: String!
    $leaseStart: Date
    $leaseEnd: Date
    $initialContactDate: Date
    $initialContact: Boolean
    $followUpContactDate: Date
    $followUpContact: Boolean
    $interviewedDate: Date
    $interviewed: Boolean
    $qualifiedDate: Date
    $qualified: Boolean
  ) {
    createResident(
      unitId: $unitId
      firstName: $firstName
      lastName: $lastName
      leaseStart: $leaseStart
      leaseEnd: $leaseEnd
      initialContactDate: $initialContactDate
      initialContact: $initialContact
      followUpContactDate: $followUpContactDate
      followUpContact: $followUpContact
      interviewedDate: $interviewedDate
      interviewed: $interviewed
      qualifiedDate: $qualifiedDate
      qualified: $qualified
    ) {
      success
      message
    }
  }
`;

function CreateResident(props) {
  const unitId = props.unitId;

  const mutationCompleted = async (data) => {
    props.enqueueSnackbar(data.createResident.message, {
      variant: data.createResident.success ? "success" : "error",
    });
    await props.refetchResidents();
  };
  const mutationFailed = () => {
    props.enqueueSnackbar("Create Resident Failed", {
      variant: "error",
    });
  };
  const [createResident] = useMutation(CREATE_RESIDENT, {
    onCompleted: (data) => mutationCompleted(data),
    onError: (data) => mutationFailed(data),
  });

  const handleFormSubmit = (values, createResident, unitId) => {
    createResident({
      variables: {
        unitId,
        ...values,
      },
    });
  };
  return (
    <Form
      onSubmit={(values) => handleFormSubmit(values, createResident, unitId)}
      mutators={{
        setFieldData,
        setValue: (args, state, utils) => {
          utils.changeValue(state, args[0], () => args[1]);
        },
      }}
      render={({ handleSubmit, form }) => (
        <CreateResidentForm onSubmit={handleSubmit} form={form} />
      )}
    />
  );
}

CreateResident.propTypes = {
  refetchAuth: PropTypes.func,
  refetchResidents: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  unitId: PropTypes.string,
};
export default withRouter(withSnackbar(CreateResident));
