import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Folder from '@material-ui/icons/Folder';
import People from '@material-ui/icons/People';
import Apartment from '@material-ui/icons/Apartment';
import AddLocation from '@material-ui/icons/AddLocation';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// Internal
import DrawerItem from './drawerItem';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
});

class ProgramList extends React.Component {
  state = { open: false };

  handleClick = () => {
    this.props.toggleDrawerList('program');
  };

  render() {
    const { classes, drawerLists } = this.props;
    const open = drawerLists['program'];
    return (
      <div className={classes.root}>
        <List
          component="nav"
        >
          <ListItem button onClick={this.handleClick}>
            <ListItemIcon>
              <Folder />
            </ListItemIcon>
            <ListItemText primary="Program" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <DrawerItem
                linkTo="/residents"
                Icon={People}
                text="Residents"
              />
              <DrawerItem
                linkTo="/properties"
                Icon={Apartment}
                text="Manage Properties"
              />
              <DrawerItem
                linkTo="/add-property"
                Icon={AddLocation}
                text="Add Property"
              />
            </List>
          </Collapse>
        </List>
      </div>
    );
  }
}

ProgramList.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerList: PropTypes.func,
  drawerLists: PropTypes.element,
  auth: PropTypes.object,
};

export default withStyles(styles)(ProgramList);
