// External Imports
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useMutation, gql } from "@apollo/client";
import _ from "lodash";
import { withSnackbar } from "notistack";
import moment from "moment";
// Internal Imports

const DELETE_RESIDENT = gql`
  mutation deleteResident($id: ID!) {
    deleteResident(id: $id) {
      success
      message
    }
  }
`;

const styles = (theme) => ({
  table: {
    marginTop: theme.spacing(1),
  },
  buttonContent: {
    margin: theme.spacing(1),
  },
});

function Residents(props) {
  const history = props.history;
  const deleteResidentCompleted = async (data) => {
    props.enqueueSnackbar(data.deleteResident.message, {
      variant: data.deleteResident.success ? "success" : "error",
    });
    await props.refetchResidents();
  };
  const deleteResidentFailed = (data) => {
    props.enqueueSnackbar(data.deleteResident.message, {
      variant: "error",
    });
  };
  const [deleteResident] = useMutation(DELETE_RESIDENT, {
    onCompleted: (data) => deleteResidentCompleted(data),
    onError: (data) => deleteResidentFailed(data),
  });
  const residents = _.cloneDeep(props.residents);
  return (
    <MaterialTable
      title="Residents"
      style={{ marginTop: "32px" }}
      onRowClick={(event, rowData) => {
        event.preventDefault();
        history.push(`/resident/${rowData.id}`);
      }}
      actions={[
        {
          icon: "delete",
          tooltip: "Delete Resident",
          onClick: (event, rowData) => {
            event.preventDefault();
            deleteResident({ variables: { id: rowData.id } });
          },
        },
      ]}
      columns={[
        { title: "Status", field: "status" },
        { title: "First Name", field: "firstName" },
        { title: "Last Name", field: "lastName" },
        {
          title: "Lease Start",
          render: (rowData) =>
            rowData.leaseStart
              ? moment(new Date(rowData.leaseStart)).format("MMMM Do YY")
              : "N/A",
        },
        {
          title: "Lease End",
          render: (rowData) =>
            rowData.leaseEnd
              ? moment(new Date(rowData.leaseEnd)).format("MMMM Do YY")
              : "N/A",
        },
        {
          title: "Initial Contact Date",
          render: (rowData) =>
            rowData.initialContactDate
              ? moment(new Date(rowData.initialContactDate)).format(
                  "MMMM Do YY"
                )
              : "N/A",
        },
        {
          title: "Initial Contact",
          render: (rowData) => (rowData.qualified ? "Yes" : "No"),
        },
        {
          title: "Follow Up Contact Date",
          render: (rowData) =>
            rowData.followUpContactDate
              ? moment(new Date(rowData.followUpContactDate)).format(
                  "MMMM Do YY"
                )
              : "N/A",
        },
        {
          title: "Follow Up Contact",
          render: (rowData) => (rowData.qualified ? "Yes" : "No"),
        },
        {
          title: "Interviewed Date",
          render: (rowData) =>
            rowData.interviewedDate
              ? moment(new Date(rowData.interviewedDate)).format("MMMM Do YY")
              : "N/A",
        },
        {
          title: "Interviewed",
          render: (rowData) => (rowData.qualified ? "Yes" : "No"),
        },
        {
          title: "Qualified Date",
          render: (rowData) =>
            rowData.qualifiedDate
              ? moment(new Date(rowData.qualifiedDate)).format("MMMM Do YY")
              : "N/A",
        },
        {
          title: "Qualified",
          render: (rowData) => (rowData.qualified ? "Yes" : "No"),
        },
      ]}
      data={residents}
    />
  );
}

Residents.propTypes = {
  classes: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  refetchResidents: PropTypes.func,
  residents: PropTypes.array,
  history: PropTypes.object,
};

export default withStyles(styles)(withSnackbar(Residents));
