import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tags from "./tags";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "16px",
  },
  main: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
});

class CenteredTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  renderContent = () => {
    switch (this.state.value) {
      case 0:
        return <Tags />;
      default:
        return <Tags />;
    }
  };
  componentDidMount = () => {
    // this.props.getUserData();
  };
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.main}>
        <Paper className={classes.root}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab key={0} label="Folder and File Tags" />
          </Tabs>
        </Paper>
        {this.renderContent()}
      </main>
    );
  }
}

CenteredTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(CenteredTabs));
